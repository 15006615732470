import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import {
  DebounceTimer,
  LoadingStatus,
  ToastThemes,
} from '../../../constants/common';
import { getClaimList, resetNewClaims } from '../../../redux/newClaims/action';
import ListView from '../../../atoms/DataList/ListView';
import { claimsHeadCell } from '../claims-utlis/claims-utils';
import {
  currentDate,
  getFormattedValues,
  getSGTTimeZone,
  noOfmonthfromCurrent,
} from '../../../utils/formatter';
import {
  InfoOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../molecules/ErrorState/error.component';
import { formatCurrency, showToast } from '../../../utils/common.util';
import ApproveRejectModal from './approve-reject-modal';
import { ViewAccess } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { DateRangePro } from '../../molecules/DateRange/DateRangePro';
import { PaymentMode } from '../../form-builder/utils/builder-utils';

const NewClaimsAllDashBoard = (props) => {
  const { ctId, claimsTab = [] } = props;
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [checkedItems, setCheckedItems] = useState([]);
  const { claimsList, approvalRemarksPermissions } = useSelector(
    (state) => state.newClaims
  );
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultMonths = logoImage?.defaultMonths;
  const defaultTimezone = logoImage?.defaultTimezone;
  const [loading, setLoading] = useState(-1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState([
    noOfmonthfromCurrent(defaultMonths || 3),
    currentDate,
  ]);
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  const permissions = useMemo(
    () => claimsTab.find((item) => item?.id === Number(ctId)),
    [ctId, claimsTab]
  );
  const { fkModuleId, paymentMode } = permissions || {};
  useEffect(() => {
    if (currentUserRoleId && ctId) {
      setLoading(LoadingStatus.Loading);
      dispatch(
        getClaimList(
          ctId,
          currentUserRoleId,
          searchQuery,
          getFormattedValues('YYYY-MM-DD', selectedDate[0]),
          getFormattedValues('YYYY-MM-DD', selectedDate[1])
        )
      )
        .then(() => {
          setLoading(LoadingStatus.Success);
        })
        .catch((err) => {
          showToast(err, ToastThemes.error);
          setLoading(LoadingStatus.Failure);
        });
    }
  }, [currentUserRoleId, ctId, debouncedVal, selectedDate]);

  useEffect(() => {
    setCheckedItems([]);
  }, [claimsList]);

  const handleAddClick = () => {
    navigate('create-claim');
  };

  const rowData = claimsList?.map((items) => {
    const {
      id,
      referenceNumber,
      claimType,
      createdAt,
      amount,
      status,
      claimantName,
      attachments,
      departmentName,
      userBasedDropdown,
      distance,
      computedAmount,
      paymentDate,
    } = items;
    return {
      referenceNumber: referenceNumber,
      name:
        fkModuleId?.permissions[0]?.viewAccess !== ViewAccess.MY_ACCESS
          ? claimantName
          : { hide: true },
      dept: departmentName,
      type: claimType,
      createdAt: getFormattedValues('YYYY-MM-DD', createdAt),
      amount: formatCurrency(Number(amount)),
      payDate:
        fkModuleId?.permissions[0]?.viewAccess === ViewAccess.MY_ACCESS ||
        fkModuleId?.permissions[0]?.viewAccess ===
          ViewAccess.APPROVER_APPROVE_ACCESS
          ? paymentDate
            ? paymentMode?.paymentOptionCode ===
              PaymentMode.PaymentMonthThroughAccountsPayable
              ? getFormattedValues('MMM YYYY', paymentDate)
              : getSGTTimeZone(paymentDate, 'DD/MM/YYYY', defaultTimezone)
            : ''
          : { hide: true },
      status:
        fkModuleId?.permissions[0]?.viewAccess === ViewAccess.MY_ACCESS &&
        status === 'SENT BACK'
          ? 'RE-SUBMIT'
          : status,
      action: (
        <IconButton onClick={() => navigate({ pathname: `${id}` })}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        value: {
          id,
          referenceNumber,
          status,
          attachments,
          amount,
          claimantName,
          userBasedDropdown,
          distance,
          computedAmount,
        },
        hide: true,
      },
    };
  });

  const isDisabledReset = () => {
    return (
      selectedDate[0].isSame(noOfmonthfromCurrent(defaultMonths || 3)) &&
      selectedDate[1].isSame(currentDate)
    );
  };

  const resetFilters = () => {
    setSelectedDate([noOfmonthfromCurrent(defaultMonths || 3), currentDate]);
  };

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <Box sx={{ backgroundColor: 'white' }}>
        <Box display="flex" justifyContent="flex-end" gap={2} mb={1} p={2}>
          <DateRangePro
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Button
            startIcon={<SettingsBackupRestoreOutlined />}
            sx={{ textTransform: 'capitalize', mt: -1.5 }}
            disabled={isDisabledReset()}
            onClick={resetFilters}
          >
            Clear
          </Button>
          {(fkModuleId?.permissions[0]?.viewAccess ===
            ViewAccess.APPROVER_APPROVE_ACCESS ||
            fkModuleId?.permissions[0]?.viewAccess ===
              ViewAccess.ALL_APPROVED_ACCESS) && (
            <Box pr={1}>
              <ApproveRejectModal
                checkedItems={checkedItems}
                currentUserRoleId={currentUserRoleId}
                moduleId={fkModuleId?.id}
                viewAccess={fkModuleId?.permissions[0]?.viewAccess}
                claimTypeId={ctId}
                searchQuery={searchQuery}
                selectedDate={selectedDate}
                approvalRemarksPermissions={approvalRemarksPermissions}
              />
            </Box>
          )}
        </Box>
        <ListView
          headCells={claimsHeadCell({
            my_access:
              fkModuleId?.permissions[0]?.viewAccess !== ViewAccess.MY_ACCESS,
            approver_access:
              fkModuleId?.permissions[0]?.viewAccess !==
              ViewAccess.APPROVER_APPROVE_ACCESS,
            paymode:
              paymentMode?.paymentOptionCode ===
              PaymentMode.PaymentMonthThroughAccountsPayable,
          })}
          rowsData={rowData || []}
          showSerialNo={true}
          showCheckbox={true}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          allowSelectAll={true}
          onAddClick={handleAddClick}
          showAddIcon={fkModuleId?.permissions[0]?.allowAdd || false}
        />
      </Box>
    </>
  );
};

export default NewClaimsAllDashBoard;
