import React, { useCallback, useState } from 'react';
import ApiSelectTag from '../../../../atoms/Select/ApiSelectTag';
import {
  userEntAddAndSubField,
  userEntitlementsFields,
} from './user-entitlement.utils';
import { BuilderServices } from '../../../../services/builder';
import { EntitlementsService } from '../../../../services/entitlementsService';
import { showToast } from '../../../../utils/common.util';
import { ToastThemes } from '../../../../constants/common';
import { Box, Grid } from '@mui/material';
import { entitlementsConfigs } from '../../../form-builder/utils/entitlement.util';
import FieldView from '../../../molecules/FieldView/FieldView';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import FormEdit from '../../common/Form/FormEdit';
import ActionHeader from '../../Organization/ActionHeader/ActionHeader';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getFormBody } from '../../Organization/utils/userOnboarding/userOnboarding.util';
import { OnboardingServices } from '../../../../services/onboarding';
import { CompBenServices } from '../../../../services/compBen';
import EntitlementAudit from './UserEntitlementAudit';

const UserEntitlement = (props) => {
  const { empId, userName } = props;
  const [benefitTypes, setBenefitTypes] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const [entitlementView, setEntitlementView] = useState({});
  const [loading, setLoading] = useState(false);
  const [userDept, setUserDept] = useState([]);
  const [entitlementAuditData, setEntitlementAuditData] = useState([]);

  const { currentUserRoleId } = useSelector((state) => state.main);

  const currencySymbol = JSON.parse(
    localStorage.getItem('orgInfo')
  )?.currencySymbol;

  const handleDropdownClick = async () => {
    return new EntitlementsService()
      .getBenfitsRole(currentUserRoleId)
      .then((res) => {
        setBenefitTypes(res);
        return true;
      });
  };

  const handleDeptDropdownClick = () => {
    return new OnboardingServices()
      .getEmployeeDepartments(empId)
      .then((res) => {
        setUserDept(res);
        return true;
      });
  };

  const onChangeBenefit = async (event) => {
    try {
      const { value } = event.target;
      const res = await new EntitlementsService().getUserEntitlement(
        value.id,
        empId
      );
      setEntitlementView(res);
      setFormInfo((prev) => {
        return {
          ...prev,
          [userEntitlementsFields.benefitTypes]: value?.id,
          [userEntitlementsFields.addAmount]: 0,
          [userEntitlementsFields.deductionAmount]: 0,
          [userEntitlementsFields.deptId]: res?.fkBenefitId?.isMultiDepartment
            ? res?.fkDeptId
            : null,
        };
      });
      const auditLogs = await new CompBenServices().getUserEntitlementAudit(
        value.id,
        empId,
        res.fkBenefitId.isMultiDepartment ? res.fkDeptId.id : null
      );
      setEntitlementAuditData(auditLogs);
    } catch (err) {
      setEntitlementView({});
      showToast(err?.response?.data?.message, ToastThemes.error);
    }
  };
  const onDeptChange = async (event) => {
    try {
      const { value } = event.target;
      const res = await new EntitlementsService().getUserEntitlement(
        formInfo?.benefitId,
        empId,
        value?.id
      );
      setEntitlementView(res);
      setFormInfo((prev) => {
        return {
          ...prev,
          [userEntitlementsFields.addAmount]: 0,
          [userEntitlementsFields.deductionAmount]: 0,
          [userEntitlementsFields.deptId]: value?.id,
        };
      });
      const auditLogs = await new CompBenServices().getUserEntitlementAudit(
        formInfo?.benefitId,
        empId,
        value?.id
      );
      setEntitlementAuditData(auditLogs);
    } catch (err) {
      setEntitlementView({});
      showToast(err?.response?.data?.message, ToastThemes.error);
    }
  };

  const isDisabledSave = () => {
    if (
      (formInfo?.[userEntitlementsFields.addAmount] || 0) -
        (formInfo?.[userEntitlementsFields.deductionAmount] || 0) ===
        0 ||
      isEmpty(entitlementView)
    ) {
      return true;
    }
    return false;
  };

  const updateUserEntitlement = async () => {
    if (!formInfo?.[userEntitlementsFields.benefitTypes]) {
      showToast('Select Benefit Type', ToastThemes.error);
      return;
    }
    try {
      setLoading(true);
      const info = getFormBody(formInfo);
      const res = await new EntitlementsService().updateUserEntitlement(
        info,
        empId
      );
      setEntitlementView((prev) => {
        return { ...prev, ...res };
      });
      const auditLogs = await new CompBenServices().getUserEntitlementAudit(
        info.benefitId,
        empId,
        info.deptId
      );
      setEntitlementAuditData(auditLogs);
    } catch (err) {
      showToast(err?.response?.data?.message, ToastThemes.error);
    } finally {
      setLoading(false);
      setFormInfo((prev) => {
        return {
          ...prev,
          [userEntitlementsFields.addAmount]: 0,
          [userEntitlementsFields.deductionAmount]: 0,
        };
      });
    }
  };

  return (
    <>
      <CircularLoader show={loading} />
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={userName || 'New User'}
          showPublish
          showPublishModal={false}
          publishButtonText="Save"
          onPublishClick={updateUserEntitlement}
          showSave={false}
          disabledPublish={isDisabledSave()}
        />
      </Box>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item md={8}>
          <Grid container rowSpacing={3} columnSpacing={12} pr={8}>
            <Grid item md={6}>
              <ApiSelectTag
                labelText={'Select Benefit Type'}
                onOpen={handleDropdownClick}
                value={formInfo?.[userEntitlementsFields.benefitTypes]}
                dropDownList={benefitTypes}
                onchange={onChangeBenefit}
                name={userEntitlementsFields.benefitTypes}
                size="small"
                fullWidth
              />
            </Grid>
            {entitlementView?.fkBenefitId?.isMultiDepartment && (
              <Grid item md={6}>
                <ApiSelectTag
                  labelText={'Select Department'}
                  onOpen={handleDeptDropdownClick}
                  value={formInfo?.[userEntitlementsFields.deptId]}
                  dropDownList={userDept}
                  onchange={onDeptChange}
                  name={userEntitlementsFields.deptId}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
          <Box sx={{ width: '100%' }} mt={2} mb={2}>
            <Grid container rowSpacing={3} columnSpacing={12} pr={8}>
              {entitlementsConfigs.map(function (data) {
                const {
                  startDate,
                  endDate,
                  entitlementAmt,
                  entitlementBalanceAmt,
                } = entitlementView;
                return (
                  <>
                    <Grid item md={3}>
                      <FieldView
                        labelText={data.label}
                        labelValue={data.getter(
                          {
                            startDate,
                            endDate,
                            entitlementAmt,
                            entitlementBalanceAmt,
                          },
                          currencySymbol
                        )}
                        required={data.required}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
          {!isEmpty(entitlementView) && (
            <FormEdit
              list={userEntAddAndSubField}
              formInfo={formInfo}
              setFormData={setFormInfo}
              modal={false}
              padding={0}
              hideFields={false}
            />
          )}
        </Grid>
        <Grid item md={4}>
          <EntitlementAudit data={entitlementAuditData} />
        </Grid>
      </Grid>
    </>
  );
};

export default UserEntitlement;
