import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import {
  departments,
  locations,
  onboarding,
  rolesAndPermission,
} from '../components/OnBoarding/Organization/constants/onboarding.constants';
import { FormFieldTypes, LoadingStatus } from '../constants/common';

export const decodeJWT = (token) => {
  const decodeToken = jwtDecode(token);
  return decodeToken;
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const sortRolesArray = (roles) => {
  return roles.sort((a, b) => {
    return a.list_order - b.list_order;
  });
};

export const getCommaSeparatedStringFromList = (list) =>
  list?.map((item) => item?.name).join(', ');

export const showToast = (message, theme) => {
  toast[theme](message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const formatCurrency = (
  value,
  percisionNumber = 2,
  format = 'en-SG'
) => {
  return value.toLocaleString(format, {
    style: 'decimal',
    minimumFractionDigits: percisionNumber,
    maximumFractionDigits: percisionNumber,
  });
};

export const allowedKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'Backspace',
  'Enter',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Delete',
  'Shift',
  'Control',
];

export const preventKeyPress = (event) => {
  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
};

export const isValidDate= (dateString) => {
  const patterns = [
    /^(\d{1,2})[/.-](\d{1,2})[/.-](\d{4})$/,  // DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY
    /^(\d{4})[/.-](\d{1,2})[/.-](\d{1,2})$/,  // YYYY/MM/DD or YYYY-MM-DD or YYYY.MM.DD
    /^(\d{4})(\d{2})(\d{2})$/,              // YYYYMMDD
    /^(\d{2})(\d{2})(\d{4})$/,              // MMDDYYYY
  ];
if(dateString){
  for (const pattern of patterns) {
    const match = dateString.match(pattern);
    if (match) return true;
  }
}
  return false;
};

export const getFieldTypeString = (fieldType) => {
switch(fieldType?.trim()){
  case FormFieldTypes.Date : {
    return 'Date';
  }
  case FormFieldTypes.Number: {
    return 'Number';
  }
  default : break;
}
};