import API from './api';

export class OnboardingServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL || '';
  constructor() {
    super(OnboardingServices.baseURL);
  }


  getCompanyInfo = async (roleId) => (
    this.responsify(await this.instance.get('onboarding/company-profile/list', {
      params: {
        roleId
      }
    }))
  );

  postCompanyInfo = async (companyInfo, roleId) => (
    this.responsify(await this.instance.post('onboarding/company-profile/create',
      companyInfo,
      {
        params: {
          roleId
        }
      }
    ))
  );

  updateCompanyInfo = async (companyInfo, roleId) => (
    this.responsify(await this.instance.put('onboarding/company-profile/update',
      companyInfo, {
      params: {
        roleId
      }
    }
    ))
  );

  getDepartmentInfo = async (roleId, searchQuery) => (
    this.responsify(await this.instance.get('onboarding/department/list', { params: { roleId, searchQuery } }))
  );
  getSubDepartmentInfo = async () => (
    this.responsify(await this.instance.get('onboarding/department/sub-department/list'))
  );

  postDepartment = async (roleId, deptName) => (
    this.responsify(await this.instance.post('onboarding/department/create',
      { name: deptName }, { params: { roleId } }
    ))
  );

  getLocationInfo = async (locName) => (
    this.responsify(await this.instance.get(`onboarding/locations/list${(locName) ? `?searchQuery=${locName}` : ''}`))
  );

  postLocation = async (locName) => (
    this.responsify(await this.instance.post('onboarding/locations/create',
      {
        name: locName
      }
    ))
  );

  fetchEmployeeInfo = async (empId, roleId) => (
    this.responsify(await this.instance.get(`onboarding/department/employees/${empId}`, { params: { roleId } }))
  );

  updateDepartmentInfo = async (params, id, roleId) => (
    this.responsify(await this.instance.put(`onboarding/department/update/${id}`,
      {
        managerId: params.managerId,
        supervisorId: params.supervisorId,
        name: params.name
      }, { params: { roleId } }

    ))
  );
  getRolesList = async (roleName) => (
    this.responsify(await this.instance.get(`roles-and-permissions/list-roles${(roleName) ? `?searchQuery=${roleName}` : ''}`))
  );

  fetchCompanyOnboardingPermissions = async (roleId, moduleId) => (
    this.responsify(await this.instance.get(`roles-and-permissions/module?roleId=${roleId}&moduleId=${moduleId}`))
  );

  publishCompanyInfo = async (roleId) => (
    this.responsify(await this.instance.put('onboarding/company-profile/publish', {}, {
      params: {
        roleId
      }
    }))
  );

  postUserInfo = async (info) => (
    this.responsify(await this.instance.post('user-onboarding/profile/draft', info))
  );

  updateUserInfo = async (id, userInfo) => {
    return (this.responsify(await this.instance.put(`user-onboarding/profile/update/${id}`, userInfo)));
  };

  getUsersList = async (roleId, searchQuery, startDate, endDate) => (
    this.responsify(await this.instance.get('user-onboarding/profile/list-all', { params: { roleId, searchQuery, startDate, endDate } }))
  );

  publishUser = async (id) => (
    this.responsify(await this.instance.put(`user-onboarding/profile/publish/${id}`))
  );

  deleteDepartment = async (roleId, deptId) => {
    return (
   
    this.responsify(await this.instance.put(`onboarding/department/delete/${deptId}?roleId=${roleId}`))
  );};

  deleteLocation = async (locId) => (
    this.responsify(await this.instance.put(`onboarding/locations/delete/${locId}`)
    ));

  updateLocation = async (name, id) => (
    this.responsify(await this.instance.put(`onboarding/locations/update/${id}`,
      {
        name: name
      },
    ))
  );

  getUserDetails = async (id, roleId, currentUserId) => (
    this.responsify(await this.instance.get(`user-onboarding/profile/details/${id}`,
      {
        params: {
          roleId,
          currentUserId
        }
      }))
  );

  getRolesConfig = async (id) => (
    this.responsify(await this.instance.get('roles-and-permissions/modules_permissions', {
      params: {
        role: id
      }
    }))
  );

  updateRolePermissions = async (params) => (
    this.responsify(await this.instance.post('roles-and-permissions/update_permission', params))
  );

  createRole = async (params) => (
    this.responsify(await this.instance.post('roles-and-permissions/create-role', {
      role_name: params,
      position_level: 'L1'
    }))
  );

  getUserDocumentsList = async (id) => (
    this.responsify(await this.instance.get(`user-onboarding/profile/documents/${id}`))
  );

  getAllCities = async () => (
    this.responsify(await this.instance.get('onboarding/locations/list-all'))
  );

  getUserGender = async () => (
    this.responsify(await this.instance.get('user-onboarding/profile/genders'))
  );

  getUserBloodGroup = async () => (
    this.responsify(await this.instance.get('user-onboarding/profile/blood-groups'))
  );

  getUserWorkStatus = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/hr-status'))
  );

  getUserDesignation = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/designations'))
  );

  getUserOnboardingMenu = async () => (
    this.responsify(await this.instance.get('user-onboarding/profile/list-multi-status'))
  );

  updateUserStatus = async (info) => (
    this.responsify(await this.instance.put('user-onboarding/profile/update-multi-status', info))
  );

  getDesignation = async (name) => (
    this.responsify(await this.instance.get(`onboarding/designation/list${(name) ? `?searchQuery=${name}` : ''}`))
  );

  deleteDesignation = async (designationId) => (
    this.responsify(await this.instance.put(`onboarding/designation/delete/${designationId}`)
    ));

  postDesignation = async (name) => (
    this.responsify(await this.instance.post('onboarding/designation/create',
      {
        shortName: name
      }
    ))
  );

  updateDesignation = async (params, id) => (
    this.responsify(await this.instance.put(`onboarding/designation/update/${id}`,
      {
        shortName: params.shortName,
        longName: params.longName,
        fkGrade: params.fkGrade
      },
    ))
  );

  getUserCostCentre = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/cost-centres'))
  );

  getUserEmploymentTypes = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/emp-types'))
  );

  getUserOrgDivisions = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/divisions'))
  );

  getUserBusinessUnits = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/business-units'))
  );

  getUserNationality = async () => (
    this.responsify(await this.instance.get('onboarding/locations/countries'))
  );

  getUserReligion = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/religion'))
  );

  getUserRace = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/ethnicity'))
  );

  fetchLogo = async () => (
    this.responsify(await this.instance.get('/onboarding/company-profile/info'))
  );

  getUserFunction = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/list-user-functions'))
  );

  getUserCategory = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/list-user-categories'))
  );

  getUserWorkAuthorization = async (nationalityId) => (
    this.responsify(await this.instance.get('/user-onboarding/profile/work-auth-by-nationality', {params:{
      nationalityId
    }}))
  );

  getDepartmentDropdownOptions = async () => (
    this.responsify(await this.instance.get('/onboarding/department/list-all'))
  );

  getActivatedUserOption = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/published'))
  );

  getEmployeeFormConfig = async (id) => (
    this.responsify(await this.instance.get('/user-onboarding/profile/employment', {params : {
      id
    }}))
  );

  getEducationFormConfig = async (id) => (
    this.responsify(await this.instance.get('/user-onboarding/profile/education', {params : {
      id
    }}))
  );

  postEmployeeInfo = async (info) => (
    this.responsify(await this.instance.put('/user-onboarding/profile/employment', info))
  );

  postEducationInfo = async (info) => (
    this.responsify(await this.instance.put('/user-onboarding/profile/education', info))
  );

  getAssignUserTagOption = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/assign-tag'))
  );

  getEmergencyRelationOption = async () => (
    this.responsify(await this.instance.get('/user-onboarding/profile/list-relations'))
  );

  getGradeList = async () => (
    this.responsify(await this.instance.get('/onboarding/designation/grades'))
  );

  getAllMyJourney = async (employeeId) => (
    this.responsify(await this.instance.get('/user-onboarding/profile/my-journey',{params:{employeeId}}))
  );
  
  getBankDetails = async (employeeId) => (
    this.responsify(await this.instance.get('/user-onboarding/profile/bank-details',{params:{employeeId}})) );

  postBankDetails =   async (body) => (
    this.responsify(await this.instance.post('/user-onboarding/profile/bank-details', body)) );

    getEmployeeDepartments = async (employeeId) => (
      this.responsify(await this.instance.get('/user-onboarding/profile/employee-departments',{params:{employeeId}})) );
    
}