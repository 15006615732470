import React from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';
import {
  currentDate,
  getFormattedDate,
  getSGTTimeZone,
  isDateAfter,
} from '../../../../utils/formatter';
import { DateFormatters } from '../../../../constants/dateConstants';
import { useSelector } from 'react-redux';

const EntitlementAudit = ({ data }) => {
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultAduitLogTimezone = logoImage?.defaultAduitLogTimezone;
  return (
    <Box sx={{ padding: 2 }}>
      {data.map((item) => (
        <Paper elevation={3} key={item.id} sx={{ padding: 2, marginBottom: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Entitlement Period:{' '}
            {getFormattedDate(
              item.startDate,
              DateFormatters.YYYYMMDD,
              DateFormatters.ddmmyyyy
            )}
            -
            {getFormattedDate(
              item.endDate,
              DateFormatters.YYYYMMDD,
              DateFormatters.ddmmyyyy
            )}
          </Typography>
          <Typography variant="body1">
            Created By: {item.createdBy.displayName}
          </Typography>
          <Typography variant="body1">
            Created On:{' '}
            {`${getSGTTimeZone(
              item.created_utc,
              DateFormatters.ddmmyyyyhhmm,
              defaultAduitLogTimezone
            )} ${defaultAduitLogTimezone === 'Asia/Singapore' ? 'SGT' : 'UTC'}`}
          </Typography>
          <Typography variant="body1">
            Status:{' '}
            {item.isActive && isDateAfter(item.endDate, currentDate)
              ? 'Active'
              : 'Inactive'}
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Box>
            {item.entitlementAudits.map((audit) => (
              <Paper
                key={audit.id}
                elevation={2}
                sx={{
                  padding: 2,
                  marginBottom: 2,
                  backgroundColor: '#f0f4ff',
                }}
              >
                <Typography variant="body2">
                  Old Balance: {audit.oldBalance}
                </Typography>
                <Typography variant="body2">
                  New Balance: {audit.newBalance}
                </Typography>
                <Typography variant="body2">
                  Balance Change:{' '}
                  <span
                    style={{
                      color: audit.balanceChangeAmount > 0 ? 'green' : 'red',
                    }}
                  >
                    {audit.balanceChangeAmount}
                  </span>
                </Typography>
                <Typography variant="body2">
                  Updated By: {audit.changedBy.displayName}
                </Typography>
                <Typography variant="body2">
                  Updated On:{' '}
                  {`${getSGTTimeZone(
                    audit.changedOn,
                    DateFormatters.ddmmyyyyhhmm,
                    defaultAduitLogTimezone
                  )} ${
                    defaultAduitLogTimezone === 'Asia/Singapore' ? 'SGT' : 'UTC'
                  }`}
                </Typography>
              </Paper>
            ))}
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default EntitlementAudit;
