import { Box, FormControl, Grid, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import { NewClaimsService } from '../../../services/newClaims';
import { useSelector } from 'react-redux';
import SelectTag from '../../../atoms/Select/SelectTag';
import ICON from '../../../assets/OvertimeClaims.svg';

const NewClaimRHS = ({setShowRightBar}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const [claimTypesInCategory, setClaimTypesInCategory] = useState([]);
  const [selectedClaimType, setSelectedClaimType] = useState(null);

  const { currentUserRoleId } = useSelector((state) => state.main);

  useEffect(() => {
    if (currentUserRoleId) {
      new NewClaimsService()
        .getAllValidCategories(currentUserRoleId)
        .then((res) => {
          setCategories(res);
          setShowRightBar(res.length > 0 ? true: false);
        });
    }
  }, [currentUserRoleId]);

  const openModelForClaimTypeSelect = (category) => {
    setClaimTypesInCategory(category?.claims);
    setSelectedCategoryName(category?.categoryname);
    setShowModal(true);
    setSelectedClaimType(null);
  };

  const onSelectClaimType = async (e) => {
    const selectedClaimType = e.target.value;
    setSelectedClaimType(selectedClaimType);
    if (selectedClaimType) {
      navigate(`/new-claims/${selectedClaimType}/create-claim`);
    }
  };
  if (categories.length) {
    return (
      <>
        <Box>
          <CardUI background="#32333C" padding= '0px 0px 10px 0px'>
            <div className="create-new-claim-links">
              <h3>Raise A Claim</h3>
              <div className="links-wrap">
                {categories &&
                  categories.map((category) => {
                    return (
                      <div
                        className="link-wrap"
                        key={category.categoryid}
                        onClick={() => openModelForClaimTypeSelect(category)}
                      >
                        <div className="link-icon-wrap">
                          <span>
                            <img
                              src={ICON}
                              alt={category.categoryname}
                            />
                          </span>
                        </div>
                        <div className="link-title-wrap">
                          <span>{category.categoryname}</span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </CardUI>
        </Box>
        <Modal
          show={showModal}
          onCancel={() => setShowModal(false)}
          header={<h2>Choose {selectedCategoryName} Type</h2>}
          contentClass="modal-content"
          footerClass="footer-content"
          submitButton={
            <ButtonUI
              backgroundColor="#00AC94"
              color="#ffffff"
              size="small"
              padding="3px 20px"
              onclick={() => setShowModal(false)}
            >
              Close
            </ButtonUI>
          }
        >
          <Grid item xs={12}>
            <label htmlFor="">Select</label>
            <SelectTag
              name="select-claim-type"
              dropDownList={claimTypesInCategory}
              size="small"
              fullWidth
              value={selectedClaimType || ''}
              onchange={onSelectClaimType}
            />
          </Grid>
        </Modal>
      </>
    );
  }
  return <></>;
};

export default NewClaimRHS;
